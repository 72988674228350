import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Aligner = makeShortcode("Aligner");
const Box = makeShortcode("Box");
const Flex = makeShortcode("Flex");
const Button = makeShortcode("Button");
const Process = makeShortcode("Process");
const InfoBlock = makeShortcode("InfoBlock");
const Accordion = makeShortcode("Accordion");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "honour-a-social-credit-system",
      "style": {
        "position": "relative"
      }
    }}>{`Honour: A Social Credit System`}<a parentName="h1" {...{
        "href": "#honour-a-social-credit-system",
        "aria-label": "honour a social credit system permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <Aligner center mdxType="Aligner">
      <Box sx={{
        textAlign: 'justify',
        width: '70%'
      }} mdxType="Box">
        <p>{`We propose a peer-to-peer system of social credit. It is open, permissionless, and can be deployed on any EVM-compatible blockchain. The system assumes minimal structure. By virtue of four weird rules, we can invert how we represent money, in order to cultivate more healthy communal use. In short, entities hold this money as a liability that simultaneously grants access to high-bandwidth, authentic communication channels by which ongoing and lively relationships may be established.`}</p>
      </Box>
    </Aligner>
    <Aligner center mdxType="Aligner">
      <Flex sx={{
        alignItems: 'center'
      }} mdxType="Flex">
  
  <Button to='https://honour.community' secondary inline mdxType="Button">
    Make money weird
  </Button>
      </Flex>
    </Aligner>
    <p><em parentName="p">{`Note: the `}<a parentName="em" {...{
          "href": "https://docs.google.com/document/d/1mdWJhBFrGx0OBJbx5fceLZSEI1zvkVuJhQwgVhpxOas/edit?usp=sharing"
        }}>{`original Google Doc`}</a>{` reveals the many ongoing conversations about how weird we can make money. You can explore the code `}<a parentName="em" {...{
          "href": "https://github.com/kernel-community/honour"
        }}>{`here`}</a>{`.`}</em></p>
    <h2 {...{
      "id": "money-made-weird",
      "style": {
        "position": "relative"
      }
    }}>{`Money Made Weird`}<a parentName="h2" {...{
        "href": "#money-made-weird",
        "aria-label": "money made weird permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`The word “weird” can be traced to “`}<em parentName="p">{`wyrd`}</em>{`”, meaning destiny. To call someone “weird” is to say they are destined: their presence has that strange and sometimes unsettling sense of something greater. To make something weird is to wire it to reach its destination, knowing well that each thing is destined for the origin from whence it emerged. `}</p>
    <p>{`Weird is the way back home.`}</p>
    <p>{`More practically, money originates from `}<a parentName="p" {...{
        "href": "/learn/module-2/debt/#communal-credit"
      }}>{`local networks of social credit`}</a>{`. Being a measure of what we value in relationship with others, money was made as it was needed and forgiven once the obligation it represented was fulfilled. We propose here a kind of money which recalls this. With one simple twist, we can undo our `}<a parentName="p" {...{
        "href": "/learn/module-7/no-paradigm/#the-reward-oriented-hierarchy"
      }}>{`reward-oriented hierarchy`}</a>{`: `}<strong parentName="p">{`holding money is a measure of obligation, not wealth`}</strong>{`.`}</p>
    <p>{`Great humorists like Rabelais`}<sup>{`1`}</sup>{`, and great humanitarians like Gandhi and Mandela, all worked by `}<strong parentName="p">{`inversion`}</strong>{`. Hold up what everyone takes for granted and reveal its cracks, and the people will laugh. Face what everyone fears and reveal it as the ground of liberation, and the people will love. Gandhi and Mandela were actually imprisoned in the same place in South Africa. That building is now the constitutional court.`}</p>
    <p>{`The single idea that underpins the reward-oriented hierarchy is this: `}<em parentName="p">{`money must be earned`}</em>{`. This ideology makes us believe that our value is measured by how much we have and control. Even people who think money shouldn’t be earned, but given (as in UBI), still operate in the same paradigm: money is an asset; a good thing to have.`}</p>
    <p>{`Money is really an `}<strong parentName="p">{`obligation`}</strong>{`; a promise to return in due course what you have been given. Most `}<a parentName="p" {...{
        "href": "/learn/module-2/banking/#banks-and-shadows"
      }}>{`modern money`}</a>{` is created from debt anyway. What if we represented this `}<a parentName="p" {...{
        "href": "/learn/module-2/engineering"
      }}>{`more truthfully`}</a>{`? What does it look like if having money is not a good thing, but a measure of your promises`}<sup>{`2`}</sup>{`?`}</p>
    <h2 {...{
      "id": "de-sign",
      "style": {
        "position": "relative"
      }
    }}>{`De-Sign`}<a parentName="h2" {...{
        "href": "#de-sign",
        "aria-label": "de sign permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Process mdxType="Process">
      <p>{`All HON are the same.`}</p>
      <p>{`HON are created only when I accept another’s proposal.`}</p>
      <p>{`I can forgive as much HON as I currently hold.`}</p>
      <p>{`HON are non-transferrable.`}</p>
    </Process>
    <p>{`The system is instantiated by means of `}<a parentName="p" {...{
        "href": "https://github.com/kernel-community/honour"
      }}>{`two, simple contracts`}</a>{`. One adapts the ERC20 token standard by removing any ability to transfer, or approve other accounts to transfer on your behalf. This contract is responsible for creating and destroying HON, and tracking the balance associated with each account. The other contract holds the logic for proposing and forgiving HON, which is done via two simple mappings`}<sup>{`3`}</sup>{`.`}</p>
    <h2 {...{
      "id": "implications",
      "style": {
        "position": "relative"
      }
    }}>{`Implications`}<a parentName="h2" {...{
        "href": "#implications",
        "aria-label": "implications permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <InfoBlock mdxType="InfoBlock">
      <p>{`"The overall goal is: `}<a parentName="p" {...{
          "href": "/learn/module-5/reveal-the-universe"
        }}>{`show a lot of truth`}</a>{`, with minimum contrivance" - Jonathan Blow`}</p>
    </InfoBlock>
    <p>{`Firstly, when money is a liability, you don’t pay when buying goods or receiving a service, you take on more tokens. This is always consensual: the provider proposes an obligation, in HON, and you accept it in order to get the goods. To emphasise the strangeness here: buying stuff `}<em parentName="p">{`increases`}</em>{` your balance, rather than decreasing it. Your account balance is a good proxy for your reputation, because the bigger it is, the `}<em parentName="p">{`less`}</em>{` creditworthy you are. `}</p>
    <p>{`Transactions either create new HON, or the proposer can ask the recipient to forgive some of the existing HON of the proposer. Money is created as needed and, when it is not needed, it is forgiven back out of our shared reality. All transactions are `}<a parentName="p" {...{
        "href": "/learn/module-5/prosocial-value/#prosocial-value-patterns"
      }}>{`consensual and prosocial`}</a>{`.`}</p>
    <p>{`This does not mean the goal when using the system is to keep a zero balance. One way of defining “community” is as a group of people who are all a little bit indebted to each other. Having tokens is useful for communicating facts (which are `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=-6BsiVyC1kM&t=1207s"
      }}>{`just values-in-time`}</a>{`); for `}<a parentName="p" {...{
        "href": "/learn/module-5"
      }}>{`teaching and learning based on said values`}</a>{`; and for `}<a parentName="p" {...{
        "href": "/learn/module-5/the-peoples-narrative/#common-knowledge"
      }}>{`establishing common knowledge`}</a>{` about trust and association. `}</p>
    <p>{`For instance, you may need to accept some HON to join a community using a token-gated chat room. This is a marvellous thing! Now, you get access to the community, but you are incentivised to connect with someone you can serve so they will help you forgive some HON, rather than to speculate on the future value of the token and how it might be used to govern others (an ideology which manages to merge tyranny and market capitalism efficiently).`}</p>
    <p>{`In old communities, `}<em parentName="p">{`you never paid people back the exact value they had given you`}</em>{`. To do this was to signify an end to the relationship. Being in debt, or being owed something, is a good reason to go on talking, visiting, arguing, and loving one another. Similarly, anyone with a zero balance is no longer an active participant in the community.`}</p>
    <h2 {...{
      "id": "better-questions",
      "style": {
        "position": "relative"
      }
    }}>{`Better Questions`}<a parentName="h2" {...{
        "href": "#better-questions",
        "aria-label": "better questions permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Accordion mdxType="Accordion">
      <ol>
        <li parentName="ol"><strong parentName="li">{`What’s to stop people from just starting new accounts to accept more HON, or forgive themselves?`}</strong></li>
      </ol>
      <Box mdxType="Box">
        <p>{`Nothing.`}</p>
        <p>{`Balance is a measure of creditworthiness. History is a measure of `}<em parentName="p">{`trustworthiness`}</em>{`. You can spin up new accounts over and over, but each will be untrusted and will have a shorter `}<a parentName="p" {...{
            "href": "https://www.simpleliberty.org/files/And_Then_There_Were_None.pdf"
          }}>{`half-life`}</a>{`, because the communities you accept HON from will grow increasingly wise to common behavioural patterns. This has always been true and is the reason humans engage so readily in out-of-band communication, or gossip`}<sup>{`4`}</sup>{`.`}</p>
        <p>{`On the flip-side, accounts may not forgive more HON than they currently hold.`}</p>
        <p>{`Still, what’s to stop someone from just spinning up thousands of spoof accounts and establishing a botnet to generate seemingly rich balances and histories? Again, nothing. But remember that the point of holding HON is to enable access to high-bandwidth, authentic communication channels. You need to be `}<em parentName="p">{`in the community, talking with others`}</em>{`. If you’re not, people are unlikely to accept the HON you propose, or forgive the HON you ask them to, no matter what your transaction history and balance look like.`}</p>
        <p>{`Moreover, if an account is forgiven by some account no-one else in that community has ever interacted with, then it is less likely that people will go on transacting with that account in the future, as it can be labelled risky-in-context. All of these metrics:`}</p>
        <ol>
          <li parentName="ol">{`which accounts a given account has accepted HON from,`}</li>
          <li parentName="ol">{`whom they have forgiven and been forgiven by,`}</li>
          <li parentName="ol">{`how “close” those accounts are to your own transactional network,`}</li>
        </ol>
        <p>{`can be shown in the interface in order to enable individuals to make more informed choices about whom to accept/trust, whom to forgive, and what kinds of responsibility they are willing to bear.`}</p>
      </Box>
    </Accordion>
    <Accordion mdxType="Accordion">
      <ol {...{
        "start": 2
      }}>
        <li parentName="ol"><strong parentName="li">{`What happens if I don’t accept someone’s proposal?`}</strong></li>
      </ol>
      <p>{`The person or business won’t let you walk out with their goods, or won’t provide the service again, as is ordinarily the case. More broadly, you are free to accept goods and services from whom you please, and to serve or provide for whom you please: the simplicity of the system enables us to enact trust for others, and responsibility for our own choices, in a more visceral, immediate, and free manner.`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <ol {...{
        "start": 3
      }}>
        <li parentName="ol"><strong parentName="li">{`How are HON valued?`}</strong></li>
      </ol>
      <Box mdxType="Box">
        <p>{`By mutual use. Just as the meaning of words is not decided by fiat but established by shared patterns of use, so is the value of HON. There is no peg, `}<a parentName="p" {...{
            "href": "https://forum.makerdao.com/t/the-target-rate-feedback-mechanism-an-introduction/2319"
          }}>{`TRFM`}</a>{`, or `}<a parentName="p" {...{
            "href": "https://en.wikipedia.org/wiki/PID_controller"
          }}>{`PID`}</a>{` Controller. `}</p>
        <p>{`HON is intended for lively community use, as you can’t transfer it: you can only accept more or forgive others some of their balance. Its design precludes authoritarian regime and rivalrous exchange. HON is technically fungible - one HON is just like another in the contract - but socially not. How you interpret the meaning of someone’s balance depends upon both quantity and quality: “value” includes context. `}</p>
        <p>{`That said, it clearly will not be the only, or even the “best”, money available. It is just `}<a parentName="p" {...{
            "href": "/learn/module-1/dreamers/#learning-the-language"
          }}>{`one more alternative you can opt into`}</a>{` if this kind of play interests you.`}</p>
      </Box>
    </Accordion>
    <Accordion mdxType="Accordion">
      <ol {...{
        "start": 4
      }}>
        <li parentName="ol"><strong parentName="li">{`If there is no fixed or authoritative value, how will you bootstrap the network?`}</strong>{`  `}</li>
      </ol>
      <p>{`By beginning it as an educational game in a community of care, and crafting compelling lore around its use and usefulness.`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <ol {...{
        "start": 5
      }}>
        <li parentName="ol"><strong parentName="li">{`Can history-as-trust and non-transferability work with key rotation practices?`}</strong></li>
      </ol>
      <Box mdxType="Box">
        <p>{`In short, not really. However, we can notice that key rotation has two primary uses. The first is good operational security, which can be achieved through a number of methods, not just rotation. In particular, we can achieve similar security properties through `}<a parentName="p" {...{
            "href": "https://papers.ssrn.com/sol3/papers.cfm?abstract_id=4105763"
          }}>{`sociality and community recovery mechanisms`}</a>{`. The design space for these kind of solutions ought to be massively extended by ERC 4337, commonly called "Account Abstraction".`}</p>
        <p>{`The second use is strong anonymity guarantees, which this system cannot provide. HON is not meant to work like cash - it is intended to work within communities who prioritise care and healthy, diverse flows of information/value over rivalrous exchange.`}</p>
        <p>{`That said, HON doesn’t require “real” names, or strong links between your identity and your public key, which can remain effectively pseudonymous. What matters is how you interact, and with whom you communicate.`}</p>
      </Box>
    </Accordion>
    <Accordion mdxType="Accordion">
      <ol {...{
        "start": 6
      }}>
        <li parentName="ol"><strong parentName="li">{`Are negative balances possible?`}</strong></li>
      </ol>
      <p>{`No. We are always indebted to life`}<sup>{`5`}</sup>{`, which is not ours, but given to us for a time.`}</p>
    </Accordion>
    <hr></hr>
    <p><em parentName="p">{`This post is dedicated to Brett Scott, for all he continues to do with `}<a parentName="em" {...{
          "href": "https://brettscott.substack.com/p/the-crypto-credit-alliance"
        }}>{`altered states of monetary consciousness`}</a></em>{`.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"A `}<em parentName="p">{`credit orientation to money`}</em>{` is a mental model that sees money not as a commodity (either real or fictitious), but rather as an active accounting system powered by IOUs that bind people together into inescapable interdependent meshes `}{`[...]`}{` One subtle yet crucial nuance to internalise is that a credit orientation to money is a way of thinking about money, rather than a specific prescription or specification for its exact form `}{`[...]`}{` credit thinking requires you to imagine the world as an elaborate mesh of people keeping accounts of webs of promises, relations and obligations `}{`[...]`}{` `}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`"What if the best of crypto could be fused with the best of credit thinking? What if crypto could shed its rigid monetary theory, and what if mutual credit systems could shed their small-scale backwater feeling? `}{`[...]`}{` One of the biggest cultural tasks is to bring the wealth of community-focused knowledge possessed by mutual credit practitioners into the crypto sector, whilst finding a positive outlet for the technical prowess of the techies: if done right, we might end up with more dynamic forms of liquid decentralization, with `}<strong parentName="p">{`local systems riding on global architectures`}</strong>{`."`}</p>
    </blockquote>
    <h3 {...{
      "id": "footnotes",
      "style": {
        "position": "relative"
      }
    }}>{`Footnotes`}<a parentName="h3" {...{
        "href": "#footnotes",
        "aria-label": "footnotes permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <ol>
      <li parentName="ol">{`“He made the top and bottom change places, intentionally mixed the hierarchical levels in order to discover the core of the object’s concrete reality, to free it from its shell and to show its material bodily aspect - the real being outside all hierarchical norms and values” (Mikhail Bakhtin, `}<em parentName="li">{`Rabelais and His World`}</em>{`, 403).`}</li>
      <li parentName="ol">{`"It is a question of the future, the question of the future itself, the question of a response, of a promise and of a responsibility for tomorrow. The archive: if we want to know what this will have meant, we will only know in the times to come. Perhaps. Not tomorrow but `}<a parentName="li" {...{
          "href": "https://youtu.be/Oohg3LZd898?t=1551"
        }}>{`in the times to come`}</a>{`, later on or perhaps never. A spectral messianicity is at work in the concept of the archive and ties it, like religion, like history, like science itself, to a very singular experience of the promise." - `}<a parentName="li" {...{
          "href": "http://artsites.ucsc.edu/sdaniel/230/derrida_archivefever.pdf"
        }}>{`Jacques Derrida`}</a></li>
      <li parentName="ol">{`"After his conversion, he lived the rest of his life in an entirely different economy--the nonsensical economy of grace, where two plus two equals a hundred and deficits are somehow an advantage" - Richard Rohr, writing about Francis of Assisi in `}<em parentName="li">{`Eager to Love`}</em></li>
      <li parentName="ol">{`Admittedly, gossip works well in physical localities, where people can warn their friends against those who do not reciprocate by virtue of their physical attributes. However, behavioural traits and memetic locality are approximately as difficult to change or spoof as physical location and attributes. We can leverage this to create authentic online communication channels which help encourage ongoing reciprocity and operate alongside the simple contracts.`}</li>
      <li parentName="ol">{`See below:`}</li>
    </ol>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p><em parentName="p">{`Love is reckless; not reason.`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Reason seeks a profit.`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Love comes on strong,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`consuming herself, unabashed.`}</em>{`  `}</p>
        <p><em parentName="p">{`Yet, in the midst of suffering,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`love proceeds like a millstone,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`hard surfaced and straightforward.`}</em>{`  `}</p>
        <p><em parentName="p">{`Having died of self-interest,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`she risks everything and asks for nothing.`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Love gambles away every gift God bestows.`}</em>{`  `}</p>
        <p><em parentName="p">{`Without cause God gave us Being;`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Without cause, give it back again.`}</em>{`  `}</p>
        <p>{`Jalaluddin Rumi, `}<strong parentName="p">{`Masnavi VI`}</strong>{`.`}</p>
      </Box>
    </InfoBlock>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      